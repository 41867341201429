import { MCODES } from './roles.menu.utils';

export const MENU_ITEMS_DASHBOARD = {
    state: 'dashboards',
    name: 'menuitems.dashboard',
    type: 'link',
    icon: 'av_timer',
};

export const MENU_ITEMS_TAIKHOAN = {
    state: ['account', 'account-info'],
    name: 'menuitems.tai_khoan',
    type: 'link',
    icon: 'manage_accounts',
};

export const MENU_ITEMS_LUUTRU = {
    state: ['storage', 'files-downloaded'],
    name: 'menuitems.luu_tru',
    type: 'link',
    icon: 'archive',
};

export const MENU_ITEMS_TIENICH = {
    state: 'utitlities',
    name: 'menuitems.tien_ich',
    type: 'sub',
    icon: 'extension',
    children: [
        { state: 'standard-address', name: 'menuitems.standard_address', type: 'link' },
        { state: 'standard-general-data', name: 'menuitems.standard_general_data', type: 'link' },
        { state: 'standard-staff', name: 'menuitems.standard_staff', type: 'link' },
    ],
};

export const MENU_ITEMS_BAOCAO = {
    state: ['baocao', 'list'],
    name: 'menuitems.baocao',
    type: 'link',
    icon: 'summarize',
};

export const MENU_ITEMS_DANHMUC = {
    state: 'danhmuc',
    name: 'menuitems.nhiemvu_danh_muc_list',
    type: 'sub',
    icon: 'dataset',
    children: [
        { state: 'loai_nhiem_vu', name: 'nhiemvu.danh_muc.loai_nhiem_vu', type: 'link' },
        { state: 'chuc_vu', name: 'nhiemvu.danh_muc.lanh_dao_vien_vai_tro', type: 'link' },
        { state: 'trang_thai', name: 'nhiemvu.danh_muc.trang_thai_hien_tai', type: 'link' },
        { state: 'tien_do_category', name: 'nhiemvu.danh_muc.tien_do_category', type: 'link' },
    ],
};

export const MENU_ITEMS_NHOMNHIEMVU = {
    state: ['nhomnhiemvu', 'list'],
    name: 'menuitems.nhomnhiemvu',
    type: 'link',
    icon: 'work',
};

export const MENU_ITEMS_NHOMNHIEMVU_DONVI = {
    state: 'nhomnhiemvu-donvi',
    name: 'menuitems.nhomnhiemvu_donvi',
    type: 'sub',
    icon: 'domain',
    children: [
        { state: ['list', 'dhqg-tphcm'], name: 'nhom_nhiem_vu_don_vi.routing.donvi_list_dhqg_tphcm', type: 'link' },
        { state: ['list', 'tphcm'], name: 'nhom_nhiem_vu_don_vi.routing.donvi_list_tphcm', type: 'link' },
    ],
};

export const MENU_ITEMS_NHOMNHIEMVU_NHANSU = {
    state: ['nhomnhiemvu-nhansu', 'list'],
    name: 'menuitems.nhomnhiemvu_nhansu',
    type: 'link',
    icon: 'supervised_user_circle',
};

export const MENU_ITEMS_QL_TAIKHOAN = {
    state: 'ql-taikhoan',
    name: 'menuitems.ql_taikhoan',
    type: 'sub',
    icon: 'supervisor_account',
    children: [
        { state: 'ca-nhan', name: 'ql_taikhoan.routing.ca_nhan', type: 'link' },
        { state: 'don-vi', name: 'ql_taikhoan.routing.don_vi', type: 'link' },
    ],
};

export const MENU_ITEMS: Map<string, any> = new Map<string, any>([
    [MCODES.dashboard, MENU_ITEMS_DASHBOARD],
    // [MCODES.nhiemvu, MENU_ITEMS_NHIEMVU],
    // [MCODES.danhmuc, MENU_ITEMS_DANHMUC],
    [MCODES.nhomnhiemvu, MENU_ITEMS_NHOMNHIEMVU],
    [MCODES.baocao, MENU_ITEMS_BAOCAO],
    [MCODES.nhomnhiemvu_donvi, MENU_ITEMS_NHOMNHIEMVU_DONVI],
    [MCODES.nhomnhiemvu_nhansu, MENU_ITEMS_NHOMNHIEMVU_NHANSU],
    [MCODES.luutru, MENU_ITEMS_LUUTRU],
    [MCODES.tienich, MENU_ITEMS_TIENICH],
    [MCODES.taikhoan, MENU_ITEMS_TAIKHOAN],
    [MCODES.ql_taikhoan, MENU_ITEMS_QL_TAIKHOAN],
]);

export const MENU_ITEMS_ORDER: string[] = [
    MCODES.dashboard,
    // MCODES.nhiemvu,
    MCODES.baocao,
    MCODES.nhomnhiemvu,
    MCODES.nhomnhiemvu_donvi,
    MCODES.nhomnhiemvu_nhansu,
    MCODES.ql_taikhoan,
    // MCODES.danhmuc,
    MCODES.luutru,
    MCODES.tienich,
    MCODES.taikhoan,
];
