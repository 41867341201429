import { ChangeDetectorRef, Component, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MediaMatcher } from '@angular/cdk/layout';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { MenuItems } from 'src/app/providers/authentication/menu-items.service';
import { RolesPermissionService } from 'src/app/providers/authentication/roles.service';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/providers/language.service';

@Component({
    selector: 'app-vertical-sidebar-icon',
    templateUrl: './vertical-sidebar.component.html',
    styleUrls: ['./vertical-sidebar.component.scss'],
})
export class VerticalAppSidebarIconComponent implements OnDestroy {
    // Translate
    private languageSub!: Subscription;
    private rolesPermissionSub: Subscription;

    private _mobileQueryListener: () => void;
    private translateTitle: string = '';

    public config: PerfectScrollbarConfigInterface = {};
    public mobileQuery: MediaQueryList;

    public status = true;

    public itemSelect: number[] = [];
    public parentIndex = 0;
    public childIndex = 0;

    @Input() public showClass: boolean = false;
    @Input() public isHover: boolean = false;
    @Input() public minisidebar: boolean = false;
    @Output() private notify: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private languageService: LanguageService,
        private translate: TranslateService,
        public changeDetectorRef: ChangeDetectorRef,
        public media: MediaMatcher,
        public menuItems: MenuItems,
        private title: Title,
        private rolesPermissionService: RolesPermissionService
    ) {
        this.translate.use(this.languageService.language);
        this.languageSub = this.languageService.languageSub.subscribe((newLanguage: string) => {
            this.translate.use(this.languageService.language);
            if (this.translateTitle) this.title.setTitle(this.translate.instant(this.translateTitle));
        });

        this.mobileQuery = media.matchMedia('(min-width: 768px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        // tslint:disable-next-line: deprecation
        this.mobileQuery.addListener(this._mobileQueryListener);

        this.rolesPermissionSub = this.rolesPermissionService.dataSub.subscribe((data: any) => {
            this.updateMenuitems(data);
        });
        this.updateMenuitems();
    }

    ngOnDestroy(): void {
        if (this.languageSub) this.languageSub.unsubscribe();
        // tslint:disable-next-line: deprecation
        this.mobileQuery.removeListener(this._mobileQueryListener);
        if (this.rolesPermissionSub) this.rolesPermissionSub.unsubscribe();
    }

    updateMenuitems(data: any = this.rolesPermissionService.data) {
        let menu_items = data.get('menu_items');
        this.menuItems.updateDisplay(menu_items);
    }

    setClickedRow(i: number, j: number): void {
        this.parentIndex = i;
        this.childIndex = j;
    }

    subclickEvent(): void {
        this.status = true;
    }

    scrollToTop(): void {
        document.querySelector('.page-wrapper')?.scroll({
            top: 0,
            left: 0,
        });
    }

    handleNotify(data: string) {
        this.translateTitle = data;
        if (this.translateTitle) this.title.setTitle(this.translate.instant(this.translateTitle));
        if (window.innerWidth < 1024) {
            this.notify.emit(!this.showClass);
        }
    }

    createRouterLink(state: any, childitem_state: any = undefined, child_state: any = undefined) {
        let result: string = '/';
        if (state)
            if (Array.isArray(state)) result += state.join('/');
            else result += state;
        if (childitem_state)
            if (Array.isArray(childitem_state)) result += '/' + childitem_state.join('/');
            else result += '/' + childitem_state;
        if (child_state)
            if (Array.isArray(child_state)) result += '/' + child_state.join('/');
            else result += '/' + child_state;

        return result;
    }
}
