import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { AccountService } from '../account.service';
import { HostnameService } from '../hostname.service';
import { nhiemvu_check_for_role } from './roles.nhiemvu.utils';
import { MCODES, menu_items_check_for_role } from './roles.menu.utils';
import { nhomnhiemvu_check_for_role } from './roles.nhomnhiemvu.utils';
import { nhomnhiemvu_donvi_check_for_role } from './roles.nhomnhiemvu_donvi.utils';
import { nhomnhiemvu_nhansu_check_for_role } from './roles.nhomnhiemvu_nhansu.utils';
import { baocao_check_for_role } from './roles.baocao.utils';

@Injectable({ providedIn: 'root' })
export class RolesPermissionService {
    private _data: Map<string, any> = new Map();
    private _dataSub: Subject<Map<string, any>> = new Subject();

    get data() {
        return this._data;
    }
    get dataSub() {
        return this._dataSub;
    }

    constructor(
        private accountService: AccountService,
        private hostnameService: HostnameService,
        protected readonly keyCloakService: KeycloakService
    ) {
        this._data.clear();
        this._data.set('roles', []);
        this._data.set('menu_items', [MCODES.dashboard, MCODES.taikhoan]);
        // this._data.set(MCODES.nhiemvu, {});
        this._data.set(MCODES.nhomnhiemvu, {});
        this._data.set(MCODES.baocao, {});
        this._data.set(MCODES.nhomnhiemvu_donvi, {});
        this._data.set(MCODES.nhomnhiemvu_nhansu, {});
    }

    changeForRole(newRoles: string[]) {
        let currentValues: any = this._data.get('roles');
        if (currentValues.length == newRoles.length && newRoles.every((role: string) => currentValues.includes(role)))
            return;

        this._data.set('roles', newRoles);
        this._data.set('menu_items', menu_items_check_for_role(newRoles));
        // this._data.set(MCODES.nhiemvu, nhiemvu_check_for_role(newRoles));
        this._data.set(MCODES.nhomnhiemvu, nhomnhiemvu_check_for_role(newRoles));
        this._data.set(MCODES.baocao, baocao_check_for_role(newRoles));
        this._data.set(MCODES.nhomnhiemvu_donvi, nhomnhiemvu_donvi_check_for_role(newRoles));
        this._data.set(MCODES.nhomnhiemvu_nhansu, nhomnhiemvu_nhansu_check_for_role(newRoles));
        this._dataSub.next(this._data);
    }
}
