import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountService } from '../../account.service';
import { HostnameService } from '../../hostname.service';

@Injectable({ providedIn: 'root' })
export class NhomNhiemvuDonViService {
    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService
    ) {}

    async add(data: any) {
        return this.httpClient
            .post<any>(this.hostnameService.UbndtphcmAPI_Producerv1 + '/nhomnhiemvu-donvi', data, {
                headers: { apikey: this.accountService.getAPIKey() },
            })
            .toPromise();
    }

    async addEmptyGetId(loai_don_vi: string = 'tphcm') {
        return this.httpClient
            .post<any>(
                this.hostnameService.UbndtphcmAPI_Producerv1 +
                    '/nhomnhiemvu-donvi/add-empty-get-id?loai=' +
                    loai_don_vi,
                {},
                {
                    headers: { apikey: this.accountService.getAPIKey() },
                }
            )
            .toPromise();
    }

    // async edit(nhom_id: string, data: any) {
    //     return this.httpClient
    //         .put<any>(this.hostnameService.UbndtphcmAPI_Producerv1 + '/nhomnhiemvu-donvi?id=' + nhom_id, data, {
    //             headers: { apikey: this.accountService.getAPIKey() },
    //         })
    //         .toPromise();
    // }

    async delete(nhom_id: string) {
        return this.httpClient
            .delete<any>(this.hostnameService.UbndtphcmAPI_Producerv1 + '/nhomnhiemvu-donvi?id=' + nhom_id, {
                headers: { apikey: this.accountService.getAPIKey() },
            })
            .toPromise();
    }

    async update(data: any) {
        return this.httpClient
            .put<any>(this.hostnameService.UbndtphcmAPI_Producerv1 + '/nhomnhiemvu-donvi/update', data, {
                headers: { apikey: this.accountService.getAPIKey() },
            })
            .toPromise();
    }

    async getAll() {
        return this.httpClient
            .get<any>(this.hostnameService.UbndtphcmAPI_Readv1 + '/nhomnhiemvu-donvi', {
                headers: { apikey: this.accountService.getAPIKey() },
            })
            .toPromise();
    }
}
