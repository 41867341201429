import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AccountService } from '../../account.service';
import { HostnameService } from '../../hostname.service';
import { NhomNhiemvuNhanSuService } from './nhan-su.service';

@Injectable({ providedIn: 'root' })
export class NhomNhiemvuNhanSuDataService {
    private _data: any[] = [];
    private _dataSub: Subject<any[]> = new Subject();

    public get data(): any[] {
        return this._data;
    }
    public set data(value: any[]) {
        this._data = value;
        this._dataSub.next(this._data);
    }
    public get dataSub(): Subject<any[]> {
        return this._dataSub;
    }

    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService,
        private nhomNhiemvuNhanSuService: NhomNhiemvuNhanSuService
    ) {}

    async fetch() {
        let result = await this.nhomNhiemvuNhanSuService.getAll();
        if (result.status == 'error') this._data = [];
        else
            this._data = result.data.map((row: any) => {
                return {
                    id: row.id,
                    ...row.metadata,
                    ...row.data,
                    image: row.image,
                };
            });

        this._dataSub.next(this._data);
        return this._data;
    }

    async fetchIfNotExists() {
        if (Array.isArray(this._data) && this._data.length > 0) return this._data;
        return await this.fetch();
    }

    getAccountInfo() {
        let found = this._data.find((row: any) => row.ma_nhan_su == this.accountService.getMetadata().ma_cb);
        return found ? found.id : undefined;
    }
}
