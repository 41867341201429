import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import Swal from 'sweetalert2';
import { HostnameService } from './hostname.service';

export interface AuthencationResponse {
    code: string;
    token: string;
}

export interface AccountMetadata {
    don_vi: string;
    apikey: string;
    ma_cb: string;
    avatar: string;
}

@Injectable({ providedIn: 'root' })
export class AccountService {
    constructor(
        private httpClient: HttpClient,
        private hostnameService: HostnameService,
        private keyCloakService: KeycloakService
    ) {}

    getMetadata() {
        let keyCloakInstance: any = this.keyCloakService.getKeycloakInstance();
        let attributes = keyCloakInstance.profile.attributes;

        let metadata: AccountMetadata = {
            don_vi: '',
            apikey: '',
            ma_cb: '',
            avatar: '',
        };
        if (attributes.don_vi) metadata.don_vi = attributes.don_vi[0];
        if (attributes.apikey) metadata.apikey = attributes.apikey[0];
        if (attributes.ma_cb) metadata.ma_cb = attributes.ma_cb[0];
        if (attributes.avatar) metadata.avatar = attributes.avatar[0];
        return metadata;
    }

    getUserRoles(): string[] {
        let keyCloakInstance: any = this.keyCloakService.getKeycloakInstance();
        let parseToken = keyCloakInstance?.tokenParsed;
        let userRoles: string[] = [];
        if (parseToken?.resource_access['webapp-nhiemvu']) {
            userRoles = parseToken?.resource_access['webapp-nhiemvu'].roles;
        }
        return userRoles;
    }

    getSubject() {
        return this.keyCloakService.getKeycloakInstance().subject;
    }

    async getProfile(forceReload: boolean = false) {
        await this.keyCloakService.loadUserProfile(forceReload);
        return this.keyCloakService.getKeycloakInstance().profile;
    }

    getRoles(): string[] {
        return this.keyCloakService.getUserRoles();
    }

    getAPIKey() {
        return this.getMetadata().apikey;
    }

    getToken(): string | undefined {
        return this.keyCloakService.getKeycloakInstance().token;
    }

    getUsername() {
        return this.keyCloakService.getUsername();
    }

    async updateAccountAttrs() {
        return this.httpClient
            .get<any>(this.hostnameService.UbndtphcmAPI_Producerv1 + '/ql-taikhoan/update-account-attrs', {
                headers: { apikey: this.getAPIKey() },
            })
            .toPromise();
    }
}
