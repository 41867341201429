import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { AuthGuard } from './authentication/keycloak/auth.guard';
import { ErrorComponent } from './authentication/error/error.component';

export const AppRoutes: Routes = [
    {
        path: '',
        component: FullComponent,
        canActivate: [AuthGuard],
        data: {
            someRoles: [
                'admin',
                'ubndtphcm.nhiemvu.chuyenvien',
                'ubndtphcm.nhiemvu.lanhdao',
                'ubndtphcm.nhiemvu.thanhvien',
                'ubndtphcm.nhiemvu.donvi',
            ],
        },
        children: [
            {
                path: '',
                redirectTo: '/dashboards',
                pathMatch: 'full',
            },
            {
                path: 'dashboard',
                redirectTo: '/dashboards',
                pathMatch: 'full',
            },
            {
                path: 'dashboards',
                loadChildren: () => import('./dashboards/dashboards.module').then((m) => m.DashboardsModule),
            },

            // Account
            {
                path: 'account',
                loadChildren: () => import('./account/account.module').then((m) => m.AccountModule),
            },

            // Storage
            {
                path: 'storage',
                loadChildren: () => import('./storage/files.module').then((m) => m.StorageModule),
            },

            // // Nhiemvu
            // {
            //     path: 'nhiemvu',
            //     loadChildren: () => import('./nhiem-vu/nhiem-vu.module').then((m) => m.NhiemvuModule),
            // },

            // // Danhmuc
            // {
            //     path: 'danhmuc',
            //     loadChildren: () => import('./danh-muc/danh-muc.module').then((m) => m.NhiemvuDanhmucModule),
            // },

            // Nhomnhiemvu
            {
                path: 'nhomnhiemvu',
                loadChildren: () => import('./nhom-nhiem-vu/nhom-nhiem-vu.module').then((m) => m.NhomNhiemvuModule),
            },

            // Nhomnhiemvu
            {
                path: 'nhomnhiemvu-donvi',
                loadChildren: () =>
                    import('./nhom-nhiem-vu-don-vi/don-vi.module').then((m) => m.NhomnhiemvuDonviModule),
            },

            // Nhomnhiemvu
            {
                path: 'nhomnhiemvu-nhansu',
                loadChildren: () =>
                    import('./nhom-nhiem-vu-nhan-su/nhan-su.module').then((m) => m.NhomnhiemvuNhanSuModule),
            },

            // QlTaikhoan
            {
                path: 'ql-taikhoan',
                loadChildren: () => import('./ql-taikhoan/ql-taikhoan.module').then((m) => m.QlTaiKhoanModule),
            },

            // Bangtin
            {
                path: 'bangtin',
                loadChildren: () => import('./bangtin/bangtin.module').then((m) => m.BangtinModule),
            },

            // Baocao
            {
                path: 'baocao',
                loadChildren: () => import('./bao-cao/bao-cao.module').then((m) => m.BaocaoModule),
            },
        ],
    },
    {
        path: '',
        component: AppBlankComponent,
        children: [
            {
                path: 'authentication',
                loadChildren: () =>
                    import('./authentication/authentication.module').then((m) => m.AuthenticationModule),
            },
        ],
    },
    {
        path: '**',
        // redirectTo: 'authentication/404',
        component: AppBlankComponent,
        children: [
            {
                path: '',
                component: ErrorComponent,
            },
        ],
    },
];
