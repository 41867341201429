import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountService } from '../account.service';
import { HostnameService } from '../hostname.service';
import { convertNhiemvuDataForEdit, convertNhomNhiemvuDataForEdit } from './nhom-nhiem-vu.service.utils';

@Injectable({ providedIn: 'root' })
export class NhomNhiemvuService {
    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService
    ) {}

    async add(formData: any) {
        return this.httpClient
            .post<any>(
                this.hostnameService.UbndtphcmAPI_Producerv1 + '/nhomnhiemvu',
                {
                    data: formData,
                },
                {
                    headers: { apikey: this.accountService.getAPIKey() },
                }
            )
            .toPromise();
    }

    async addEmptyGetId() {
        return this.httpClient
            .post<any>(
                this.hostnameService.UbndtphcmAPI_Producerv1 + '/nhomnhiemvu/add-empty-get-id',
                {},
                {
                    headers: { apikey: this.accountService.getAPIKey() },
                }
            )
            .toPromise();
    }

    async edit(nhom_id: string, formData: any) {
        formData = convertNhomNhiemvuDataForEdit(formData);

        return this.httpClient
            .put<any>(
                this.hostnameService.UbndtphcmAPI_Producerv1 + '/nhomnhiemvu?id=' + nhom_id,
                {
                    data: formData,
                },
                {
                    headers: { apikey: this.accountService.getAPIKey() },
                }
            )
            .toPromise();
    }

    async delete(nhom_id: string) {
        return this.httpClient
            .delete<any>(this.hostnameService.UbndtphcmAPI_Producerv1 + '/nhomnhiemvu?id=' + nhom_id, {
                headers: { apikey: this.accountService.getAPIKey() },
            })
            .toPromise();
    }

    async editPart(
        nhom_id: string,
        type: 'nhom-info' | 'nhiemvu',
        dataOfType: any,
        nhiemvu_action: 'update' | 'delete' | undefined = undefined
    ) {
        // dataOfType
        // {
        // 'ten_nhiem_vu': '...',
        // 'ten_nhiem_vu_cu': '...',
        // 'data': '...'
        // }
        // hoặc
        // {
        //   'title': '...',
        //   'styles': '...'
        // }

        if (type == 'nhiemvu' && nhiemvu_action == 'update')
            dataOfType.data = convertNhiemvuDataForEdit(dataOfType.data);

        return this.httpClient
            .post<any>(
                this.hostnameService.UbndtphcmAPI_Producerv1 +
                    '/nhomnhiemvu/part/edit?id=' +
                    nhom_id +
                    '&type=' +
                    type +
                    (nhiemvu_action ? '&action=' + nhiemvu_action : ''),
                {
                    data: dataOfType,
                },
                {
                    headers: { apikey: this.accountService.getAPIKey() },
                }
            )
            .toPromise();
    }

    async getAll() {
        return this.httpClient
            .get<any>(this.hostnameService.UbndtphcmAPI_Readv1 + '/nhomnhiemvu', {
                headers: { apikey: this.accountService.getAPIKey() },
            })
            .toPromise();
    }

    async getFileBinary(file_id: string) {
        return this.httpClient
            .get<any>(this.hostnameService.UbndtphcmAPI_Readv1 + '/nhomnhiemvu/file-binary?id=' + file_id, {
                headers: { apikey: this.accountService.getAPIKey() },
            })
            .toPromise();
    }

    async getAllFileBinary(file_ids: string[]) {
        return this.httpClient
            .post<any>(
                this.hostnameService.UbndtphcmAPI_Readv1 + '/nhomnhiemvu/get-file-binary-by-ids',
                {
                    ids: file_ids,
                },
                {
                    headers: { apikey: this.accountService.getAPIKey() },
                }
            )
            .toPromise();
    }

    async downloadTemplate() {
        return this.httpClient
            .get<any>(this.hostnameService.UbndtphcmAPI_Readv1 + '/nhomnhiemvu/template', {
                headers: { apikey: this.accountService.getAPIKey() },
            })
            .toPromise();
    }

    async uploadFileTemplate(file: File, inputType: string) {
        let formData = new FormData();
        formData.set('file', file);
        formData.set('type', inputType);
        return this.httpClient
            .post<any>(this.hostnameService.UbndtphcmAPI_Producerv1 + '/nhomnhiemvu/template', formData, {
                headers: { apikey: this.accountService.getAPIKey() },
            })
            .toPromise();
    }

    async extractAll(with_images: boolean, with_files: boolean) {
        return this.httpClient
            .get<any>(
                this.hostnameService.UbndtphcmAPI_Readv1 +
                    '/nhomnhiemvu/extract-all?with-images=' +
                    (with_images ? 'true' : 'false') +
                    '&with-files=' +
                    (with_files ? 'true' : 'false'),
                {
                    headers: { apikey: this.accountService.getAPIKey() },
                }
            )
            .toPromise();
    }

    async getDashboard() {
        return this.httpClient
            .get<any>(this.hostnameService.UbndtphcmAPI_Readv1 + '/nhomnhiemvu-dashboard', {
                headers: { apikey: this.accountService.getAPIKey() },
            })
            .toPromise();
    }

    async sendEmail(element: any) {
        return this.httpClient
            .post<any>(this.hostnameService.UbndtphcmAPI_Producerv1 + '/nhomnhiemvu-send-email', element, {
                headers: { apikey: this.accountService.getAPIKey() },
            })
            .toPromise();
    }

    async addMessage(
        nhom_id: any,
        index: number,
        element: any,
        is_send_email: boolean = false,
        email_info: any = undefined
    ) {
        return this.httpClient
            .post<any>(
                this.hostnameService.UbndtphcmAPI_Producerv1 +
                    '/nhomnhiemvu-send-email/message?nhom-id=' +
                    nhom_id +
                    '&index=' +
                    index +
                    '&is-send-email=' +
                    (is_send_email ? 'true' : 'false'),
                { element, email_info },
                {
                    headers: { apikey: this.accountService.getAPIKey() },
                }
            )
            .toPromise();
    }
}
