// CONSTABCE
export const NHOMNHIEMVU_NHANSU_CODES = {
    actions: {
        xem: 'xem', // Xem
        them: 'them', // Thêm
        xoa: 'xoa', // Xóa
        sua: 'sua', // Sửa
        template: 'template', // Tải và nhập dữ liệu bằng file excel
    },
};

// ROLES
export const STAFF_ROLES: Map<string, any> = new Map([
    [
        'ubndtphcm.nhiemvu.lanhdao',
        {
            global_actions: [
                NHOMNHIEMVU_NHANSU_CODES.actions.xem,
                NHOMNHIEMVU_NHANSU_CODES.actions.them,
                NHOMNHIEMVU_NHANSU_CODES.actions.xoa,
                NHOMNHIEMVU_NHANSU_CODES.actions.sua,
                NHOMNHIEMVU_NHANSU_CODES.actions.template,
            ],
            actions: [],
        },
    ],
    [
        'ubndtphcm.nhiemvu.chuyenvien',
        {
            global_actions: [NHOMNHIEMVU_NHANSU_CODES.actions.xem],
            actions: [],
        },
    ],
    [
        'ubndtphcm.nhiemvu.thanhvien',
        {
            global_actions: [],
            actions: [],
        },
    ],
]);

// FUNCTIONS
export function nhomnhiemvu_nhansu_check_for_role(roles: string[]) {
    let actions = new Set();
    let global_actions: Set<string> = new Set();

    roles.forEach((role: string) => {
        let role_info = STAFF_ROLES.get(role);
        if (!role_info) return;
        role_info.actions.forEach((item: string) => actions.add(item));
        role_info.global_actions.forEach((item: string) => global_actions.add(item));
    });

    return {
        actions: [...actions],
        global_actions: [...global_actions],
    };
}
