// CONSTABCE
export const NHOMNHIEMVU_CODES = {
    actions: {
        xem: 'xem', // Xem
        them: 'them', // Thêm
        xoa: 'xoa', // Xóa
        sua: 'sua', // Sửa
        template: 'template', // Tải và nhập dữ liệu bằng file excel

        nhiemvu_xem: 'nhiemvu_xem', // Xem Nhiệm vụ
        nhiemvu_them: 'nhiemvu_them', // Thêm Nhiệm vụ
        nhiemvu_xoa: 'nhiemvu_xoa', // Xóa Nhiệm vụ
        nhiemvu_sua: 'nhiemvu_sua', // Sửa Nhiệm vụ
        nhiemvu_template: 'nhiemvu_template', // Tải và nhập dữ liệu bằng file excel Nhiệm vụ
    },
};

// ROLES
export const STAFF_ROLES: Map<string, any> = new Map([
    [
        'ubndtphcm.nhiemvu.lanhdao',
        {
            global_actions: [
                NHOMNHIEMVU_CODES.actions.xem,
                NHOMNHIEMVU_CODES.actions.them,
                NHOMNHIEMVU_CODES.actions.xoa,
                NHOMNHIEMVU_CODES.actions.sua,
                NHOMNHIEMVU_CODES.actions.template,
            ],
            actions: [
                NHOMNHIEMVU_CODES.actions.nhiemvu_xem,
                NHOMNHIEMVU_CODES.actions.nhiemvu_them,
                NHOMNHIEMVU_CODES.actions.nhiemvu_xoa,
                NHOMNHIEMVU_CODES.actions.nhiemvu_sua,
                NHOMNHIEMVU_CODES.actions.nhiemvu_template,
            ],
        },
    ],
    [
        'ubndtphcm.nhiemvu.chuyenvien',
        {
            global_actions: [
                NHOMNHIEMVU_CODES.actions.xem,
                NHOMNHIEMVU_CODES.actions.them,
                NHOMNHIEMVU_CODES.actions.sua,
                NHOMNHIEMVU_CODES.actions.template,
            ],
            actions: [
                NHOMNHIEMVU_CODES.actions.nhiemvu_xem,
                NHOMNHIEMVU_CODES.actions.nhiemvu_them,
                NHOMNHIEMVU_CODES.actions.nhiemvu_sua,
                NHOMNHIEMVU_CODES.actions.nhiemvu_template,
            ],
        },
    ],
    [
        'ubndtphcm.nhiemvu.thanhvien',
        {
            global_actions: [NHOMNHIEMVU_CODES.actions.xem],
            actions: [NHOMNHIEMVU_CODES.actions.nhiemvu_xem, NHOMNHIEMVU_CODES.actions.nhiemvu_sua],
        },
    ],
]);

// FUNCTIONS
export function nhomnhiemvu_check_for_role(roles: string[]) {
    let actions = new Set();
    let global_actions: Set<string> = new Set();

    roles.forEach((role: string) => {
        let role_info = STAFF_ROLES.get(role);
        if (!role_info) return;
        role_info.actions.forEach((item: string) => actions.add(item));
        role_info.global_actions.forEach((item: string) => global_actions.add(item));
    });

    return {
        actions: [...actions],
        global_actions: [...global_actions],
    };
}
