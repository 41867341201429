import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';

export function stopAwait(miliseconds: number) {
    return new Promise<void>((resolve, reject) => setTimeout(() => resolve(), miliseconds));
}

export async function commonRequestHandle(
    translateService: TranslateService,
    handle_func: any,
    show_success: boolean = false,
    show_loading: boolean = true
) {
    let result: any;

    try {
        if (Swal.isVisible()) Swal.close();
        if (show_loading) {
            Swal.fire();
            Swal.showLoading();
        }

        result = await handle_func();
        if (result && result.status != 'success') throw Error();

        if (show_success) Swal.fire(translateService.instant('dialog.thanh_cong'), '', 'success');
        return result || 'success';
    } catch (e) {
        console.error(e);

        let msg = result?.msg;
        if (e instanceof HttpErrorResponse) msg = 'loi_ket_noi';

        if (Swal.isVisible()) Swal.close();
        Swal.fire(translateService.instant('dialog.loi'), translateService.instant('return_msg.' + msg), 'error');
        return undefined;
    }
}

export function default_eval_request_success_func(result: any) {
    return !result || result.status != 'success';
}

export function res_exists_eval_request_success_func(result: any) {
    return !result || result == 'FAIL';
}

export interface CommonRequestHandleOption {
    show_success?: boolean;
    success_message?: string;
    show_loading?: boolean;
    close_before_show_loading?: boolean;
    close_after_show_loading?: boolean;
    timeout_loading?: number;
    timeout_auto_close_success?: number;
    timeout_auto_close_error?: number;
    not_show_error?: boolean;
    eval_request_success_func?: (result: any) => boolean;
}

export async function commonRequestHandleWithOptions(
    translateService: TranslateService,
    handle_func: any,
    options: CommonRequestHandleOption
) {
    let result: any;

    try {
        if (options.close_before_show_loading) if (Swal.isVisible()) Swal.close();
        if (options.show_loading) {
            Swal.fire();
            Swal.showLoading();
        }

        result = await handle_func();
        let eval_request_func = default_eval_request_success_func;
        if (options.eval_request_success_func) eval_request_func = options.eval_request_success_func;
        if (eval_request_func(result)) throw Error('Request error');

        if (options.timeout_loading && options.timeout_loading > 0) await stopAwait(options.timeout_loading);
        if (options.close_after_show_loading) if (Swal.isVisible()) Swal.close();
        if (options.show_success)
            if (options.timeout_auto_close_success)
                await Swal.fire({
                    title: translateService.instant('dialog.thanh_cong'),
                    text: options.success_message && translateService.instant(options.success_message),
                    icon: 'success',
                    timer: options.timeout_auto_close_success || undefined,
                });
            else Swal.fire(translateService.instant('dialog.thanh_cong'), '', 'success');
        return result || 'success';
    } catch (e) {
        console.error(e);

        let msg = result?.msg;
        if (e instanceof HttpErrorResponse) msg = 'loi_ket_noi';
        if (options.not_show_error) return undefined;

        if (Swal.isVisible()) Swal.close();
        if (options.timeout_auto_close_error)
            await Swal.fire({
                title: translateService.instant('dialog.loi'),
                text: translateService.instant('return_msg.' + msg),
                icon: 'error',
                timer: options.timeout_auto_close_error || undefined,
            });
        else Swal.fire(translateService.instant('dialog.loi'), translateService.instant('return_msg.' + msg), 'error');
        return undefined;
    }
}
