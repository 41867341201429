export function convertNhiemvuDataForEdit(nhiemvu: any) {
    nhiemvu.noi_dung.forEach((noi_dung: any) => {
        let tai_lieu: any = noi_dung.tai_lieu;
        if (tai_lieu)
            tai_lieu.forEach((row: any) => {
                row.cac_file.forEach((f: any, i: number) => {
                    if (f.id) row.cac_file[i] = f.id;
                });
            });
    });
    nhiemvu.noi_dung.forEach((nd: any) =>
        nd.san_pham.hinh_anh.forEach((f: any, i: number) => {
            if (f.id) nd.san_pham.hinh_anh[i] = f.id;
        })
    );
    return nhiemvu;
}

export function convertNhomNhiemvuDataForEdit(formData: any) {
    formData = JSON.parse(JSON.stringify(formData));

    Object.values(formData.data).forEach((data: any) => {
        convertNhiemvuDataForEdit(data);
    });
    return formData;
}
