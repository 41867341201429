import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AccountService } from '../account.service';
import { HostnameService } from '../hostname.service';
import { EXAMPLE_NHOM_NHIEM_VU_DATA } from './example.data';
import { NhomNhiemvuService } from './nhom-nhiem-vu.service';

@Injectable({ providedIn: 'root' })
export class NhomNhiemvuDataService {
    private _data: any[] = [];
    private _dataSub: Subject<any[]> = new Subject();

    public get data(): any[] {
        return this._data;
    }
    public set data(value: any[]) {
        this._data = value;
        this._dataSub.next(this._data);
    }
    public get dataSub(): Subject<any[]> {
        return this._dataSub;
    }

    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService,
        private nhomNhiemvuService: NhomNhiemvuService
    ) {}

    async fetch() {
        // this._data = EXAMPLE_NHOM_NHIEM_VU_DATA;
        // console.log(this._data);

        let result = await this.nhomNhiemvuService.getAll();
        if (result.status == 'error') this._data = [];
        else
            this._data = result.data.map((row: any) => {
                return {
                    id: row.id,
                    index: row.index,
                    ...row.metadata,
                    ...row.data,
                };
            });

        this._dataSub.next(this._data);
        return this._data;
    }

    async fetchIfNotExists() {
        if (Array.isArray(this._data) && this._data.length > 0) return this._data;
        return await this.fetch();
    }
}
