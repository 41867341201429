import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class HostnameService {
    get UbndtphcmAPI() {
        return 'https://api.vnudc.com';
    }

    get UbndtphcmAPI_Readv1() {
        return "https://api.vnudc.com/api/v1/read";
        // return 'http://localhost:5000';
    }

    get UbndtphcmAPI_Producerv1() {
        return "https://api.vnudc.com/api/v1/producer";
        // return 'http://localhost:5001';
    }
}
