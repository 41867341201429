<!-- Breadcrumb -->

<div class="page-breadcrumb p-l-30 p-r-30" style="padding-top: 5px; padding-bottom: 5px">
    <div fxLayout="row wrap">
        <div fxFlex.gt-sm="60" fxFlex.gt-xs="60" fxFlex="100" class="align-self-center">
            <h4 class="page-title m-0">
                {{ pageInfo?.title.display || pageInfo?.title || pageInfo?.key | translate }}
            </h4>
        </div>
        <div fxFlex.gt-sm="40" fxFlex.gt-xs="40" fxFlex="100" class="align-self-center">
            <div class="breadcrumb d-flex align-items-center float-right float-sm-left">
                <ul class="list-style-none d-flex align-items-center">
                    <li class="breadcrumb-item active">
                        <a routerLink="/dashboard">
                            <mat-icon
                                class="material-symbols-outlined"
                                matTooltip="{{ 'menuitems.dashboard' | translate }}"
                                matTooltipClass="custom-tooltip"
                            >
                                home
                            </mat-icon>
                        </a>
                    </li>
                    <ng-template ngFor let-url [ngForOf]="pageInfo?.urls" let-last="last">
                        <li class="breadcrumb-item" *ngIf="!last" [routerLink]="url.url">
                            <a [routerLink]="url.url">{{ url.title | translate }}</a>
                        </li>
                        <li class="breadcrumb-item active" *ngIf="last">{{ url.title | translate }}</li>
                    </ng-template>
                </ul>
            </div>
        </div>
    </div>
</div>
