import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { KeycloakAuthGuard, KeycloakService } from 'keycloak-angular';
import Swal from 'sweetalert2';
import { AccountService } from '../../providers/account.service';
import { RolesPermissionService } from '../../providers/authentication/roles.service';

const ROLES = {
    SUPER_ADMIN: 'super_admin',
    ADMIN: 'admin',
    LEARNER: 'learner',
};

@Injectable({
    providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
    constructor(
        protected readonly router: Router,
        protected readonly keyCloakService: KeycloakService,
        private accountService: AccountService,
        private rolesPermissionService: RolesPermissionService,
        private translate: TranslateService
    ) {
        super(router, keyCloakService);
    }

    async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
        const everyRoles: string[] = route.data.everyRoles || [];
        const someRoles: string[] = route.data.someRoles || [];
        const unAuthRedirect: string | undefined = route.data.unAuthRedirect || undefined;

        // Force the user to log in if currently unauthenticated.
        if (!this.authenticated) {
            await this.keyCloakService.login({
                redirectUri: window.location.origin + state.url,
            });
        }

        // Check Roles
        let userRoles = this.accountService.getUserRoles();
        let isPassEvery: boolean = false;
        let isPassSome: boolean = false;

        // Check everyRoles
        if (Array.isArray(everyRoles) && everyRoles.length > 0)
            isPassEvery = everyRoles.every((role) => userRoles.includes(role));
        else isPassEvery = true;

        // Check someRoles
        if (isPassEvery)
            if (Array.isArray(someRoles) && someRoles.length > 0)
                isPassSome = someRoles.some((role) => userRoles.includes(role));
            else isPassSome = true;

        if (isPassEvery && isPassSome) {
            this.rolesPermissionService.changeForRole(userRoles);
            return true;
        }

        await this.translate.get('is_translate_ready').toPromise();
        await Swal.fire(this.translate.instant('dialog.loi'), this.translate.instant('return_msg.auth_error'), 'error');
        if (unAuthRedirect) this.router.navigateByUrl(unAuthRedirect);
        else this.keyCloakService.logout();
        return false;
    }
}
