// CONSTABCE
export const MCODES = {
    dashboard: 'dashboard', // Bảng điều khiển
    luutru: 'luutru', // Lưu trữ
    tienich: 'tienich', // Tiện ích
    taikhoan: 'taikhoan', // Tài khoản

    nhiemvu: 'nhiemvu',
    baocao: 'baocao',
    danhmuc: 'danhmuc',
    nhomnhiemvu: 'nhomnhiemvu',
    nhomnhiemvu_donvi: 'nhomnhiemvu_donvi',
    nhomnhiemvu_nhansu: 'nhomnhiemvu_nhansu',

    ql_taikhoan: 'ql_taikhoan',
};

// ROLES

// prettier-ignore
export const MENU_ITEMS_ROLES: Map<string, string[]> = new Map([
    ['admin', [
        MCODES.dashboard, MCODES.luutru, MCODES.taikhoan, 
        // MCODES.nhiemvu, 
        // MCODES.danhmuc, 
        MCODES.nhomnhiemvu, MCODES.nhomnhiemvu_donvi, MCODES.nhomnhiemvu_nhansu,
        MCODES.ql_taikhoan,
    ]],

    // CAN_BO
    ['ubndtphcm.nhiemvu.lanhdao', [
        MCODES.dashboard, MCODES.luutru, MCODES.taikhoan, 
        // MCODES.nhiemvu, 
        // MCODES.danhmuc,
        MCODES.nhomnhiemvu, MCODES.nhomnhiemvu_donvi, MCODES.nhomnhiemvu_nhansu,
        MCODES.ql_taikhoan,
        MCODES.baocao, 
    ]],
    ['ubndtphcm.nhiemvu.chuyenvien', [
        MCODES.dashboard, MCODES.luutru, MCODES.taikhoan, 
        // MCODES.nhiemvu, 
        // MCODES.danhmuc,
        MCODES.nhomnhiemvu, MCODES.nhomnhiemvu_donvi, MCODES.nhomnhiemvu_nhansu,
        MCODES.baocao, 
    ]],
    ['ubndtphcm.nhiemvu.donvi', [
        MCODES.dashboard, MCODES.luutru, MCODES.taikhoan, 
        MCODES.nhomnhiemvu, 
        MCODES.baocao, 
    ]],
    ['ubndtphcm.nhiemvu.thanhvien', [
        MCODES.dashboard, MCODES.luutru, MCODES.taikhoan, 
        MCODES.nhomnhiemvu, 
        MCODES.baocao, 
    ]],    
]);

// FUNCTIONS
export function menu_items_check_for_role(roles: string[]) {
    let menu_items: Set<string> = new Set();
    roles.forEach((role: string) => {
        let menu_for_role = MENU_ITEMS_ROLES.get(role);
        if (!Array.isArray(menu_for_role)) return;
        menu_for_role.forEach((item: string) => menu_items.add(item));
    });
    return [...menu_items];
}
