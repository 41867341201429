// CONSTABCE
export const BAOCAO_CODES = {
    actions: {
        editable: 'editable',
    },
};

// ROLES
export const STAFF_ROLES: Map<string, any> = new Map([
    [
        'ubndtphcm.nhiemvu.lanhdao',
        {
            global_actions: [BAOCAO_CODES.actions.editable],
            actions: [],
        },
    ],
    [
        'ubndtphcm.nhiemvu.chuyenvien',
        {
            global_actions: [],
            actions: [],
        },
    ],
    [
        'ubndtphcm.nhiemvu.thanhvien',
        {
            global_actions: [],
            actions: [],
        },
    ],
]);

// FUNCTIONS
export function baocao_check_for_role(roles: string[]) {
    let actions = new Set();
    let global_actions: Set<string> = new Set();

    roles.forEach((role: string) => {
        let role_info = STAFF_ROLES.get(role);
        if (!role_info) return;
        role_info.actions.forEach((item: string) => actions.add(item));
        role_info.global_actions.forEach((item: string) => global_actions.add(item));
    });

    return {
        actions: [...actions],
        global_actions: [...global_actions],
    };
}
