import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountService } from '../../account.service';
import { HostnameService } from '../../hostname.service';

@Injectable({ providedIn: 'root' })
export class NhomNhiemvuNhanSuService {
    constructor(
        private httpClient: HttpClient,
        private accountService: AccountService,
        private hostnameService: HostnameService
    ) {}

    async add(data: any) {
        return this.httpClient
            .post<any>(this.hostnameService.UbndtphcmAPI_Producerv1 + '/nhomnhiemvu-nhansu', data, {
                headers: { apikey: this.accountService.getAPIKey() },
            })
            .toPromise();
    }

    async edit(nhom_id: string, data: any) {
        return this.httpClient
            .put<any>(this.hostnameService.UbndtphcmAPI_Producerv1 + '/nhomnhiemvu-nhansu?id=' + nhom_id, data, {
                headers: { apikey: this.accountService.getAPIKey() },
            })
            .toPromise();
    }

    async delete(nhom_id: string) {
        return this.httpClient
            .delete<any>(this.hostnameService.UbndtphcmAPI_Producerv1 + '/nhomnhiemvu-nhansu?id=' + nhom_id, {
                headers: { apikey: this.accountService.getAPIKey() },
            })
            .toPromise();
    }

    async getAll() {
        return this.httpClient
            .get<any>(this.hostnameService.UbndtphcmAPI_Readv1 + '/nhomnhiemvu-nhansu', {
                headers: { apikey: this.accountService.getAPIKey() },
            })
            .toPromise();
    }
}
